import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, ToggleButton, ToggleButtonGroup, Alert, AlertTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import people from './Images/people.png';
import yellow from './Images/yellow.png';
import axios from 'axios';
import { ORG_LOGIN } from '../../api/orgAuth';

const LoginOrg = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [orgCode, setOrgCode] = useState('');
  const [loginMode, setLoginMode] = useState('email'); // Toggle between email and org login
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();

  // Handle login based on login type
  const handleLogin = () => {
    setErrorMessage('');
    setSuccessMessage('');

    const loginData = loginMode === 'org' ? { orgCode, email, password } : { email, password };
    const loginUrl = ORG_LOGIN;

    axios.post(loginUrl, loginData)
      .then((response) => {
        const { token, user } = response.data;

        // Store token and user info
        localStorage.setItem('token', token);
        localStorage.setItem('role', user.role);
        localStorage.setItem('name', user.name);
        localStorage.setItem('id', user.id);
        localStorage.setItem('isAuth', true);

        setSuccessMessage('Login successful!');
        
        // Navigate to respective dashboard
        if (user.role === 'user') {
          navigate('/dashboard/' + user.id);
        } else if (user.role === 'org') {
          navigate('/orgdashboard/' + user.id);
        } else if (user.role === 'student') {
          navigate('/studentdashboard/' + user.id);
        }
      })
      .catch((error) => {
        if (error.response) {
          setErrorMessage('Login failed: ' + error.response.data.message);
        } else {
          setErrorMessage('An error occurred during login');
        }
      });
  };

  // Check if user is already logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    
    if (token || role) {
      if (role === 'user') {
        navigate('/dashboard/' + localStorage.getItem('id'));
      } else if (role === 'org') {
        navigate('/orgdashboard/' + localStorage.getItem('id'));
      } else if (role === 'student') {
        navigate('/studentdashboard/' + localStorage.getItem('id'));
      }
    }
  }, [navigate]);
 
  return (
    <>
      <Box
        sx={{
          marginTop: '120px',
          display: 'flex',
          flexDirection: { xs: 'column-reverse', md: 'row' },
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '40%' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 500,
          }}
        >
          <Box
            component={'img'}
            src={people}
            sx={{
              width: { md: 400, xs: '80%' },
              height: 400,
            }}
          />
        </Box>

        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 500,
            flexDirection: 'column',
          }}
        >
          <Typography variant="h4" sx={{ color: '#000', marginBottom: '20px', textAlign: 'center' }}>
            Corporate {'<>'} College Collaboration Portal
          </Typography>

          <ToggleButtonGroup
            value={loginMode}
            exclusive
            onChange={(e, newMode) => setLoginMode(newMode || loginMode)} // Prevent deselecting all
            aria-label="login mode"
            sx={{ marginBottom: '20px' }}
          >
            <ToggleButton value="email" aria-label="email login">
              Email Login
            </ToggleButton>
            <ToggleButton value="org" aria-label="org login">
              ORG Login
            </ToggleButton>
          </ToggleButtonGroup>

          <Box
            sx={{
              width: { md: '40%', xs: '80%' },
              padding: '20px',
              borderRadius: '10px',
              backgroundColor: '#6a58c6',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <Typography variant="h6" sx={{ color: '#fff', marginBottom: '20px' }}>
              {loginMode === 'org' ? 'ORG Login' : 'Email Login'}
            </Typography>

            {/* Success and Error Alerts */}
            {errorMessage && (
              <Alert severity="error" sx={{ width: '100%' }}>
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
              </Alert>
            )}
            {successMessage && (
              <Alert severity="success" sx={{ width: '100%' }}>
                <AlertTitle>Success</AlertTitle>
                {successMessage}
              </Alert>
            )}

            {/* Common Fields */}
            {loginMode === 'org' ? (
              <TextField
                label="ORG Code"
                variant="outlined"
                fullWidth
                value={orgCode}
                onChange={(e) => setOrgCode(e.target.value)}
                InputProps={{
                  sx: {
                    backgroundColor: 'white',
                    borderRadius: '5px',
                  },
                }}
              />
            ) : (
              <TextField
                label="Email ID"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  sx: {
                    backgroundColor: 'white',
                    borderRadius: '5px',
                  },
                }}
              />
            )}

            <TextField
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                sx: {
                  backgroundColor: 'white',
                  borderRadius: '5px',
                },
              }}
            />

            <Button variant="contained" sx={{ background: '#000' }} onClick={handleLogin}>
              Login
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100px',
          backgroundImage: `url(${yellow})`,
          backgroundRepeat: 'repeat-x',
          backgroundSize: '20%',
          display: { xs: 'none', md: 'flex' },
        }}
      />
    </>
  );
};

export default LoginOrg;
