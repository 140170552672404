import React, { useState } from 'react';
import { Grid, Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Paper } from '@mui/material';
import { Home as HomeIcon, Person as ProfileIcon, School as LearningIcon, CalendarToday as CalendarIcon } from '@mui/icons-material';
import DateTimeSelection from '../DateTimeSelection';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { FORGOT_PASSWORD } from "../../../api/forgotPassword";

const DashboardComponent = ({ projectsCompleted, conceptsLearnt, daysLeft, trainingScore, announcements, editingBooking, formData, setFormData, handleScheduleInterview, open, setOpen, studentData }) => {
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [mockInterviewOpen, setMockInterviewOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetStatus, setResetStatus] = useState('');
  const [selected, setSelected] = useState(0);
  const [viewMode, setViewMode] = useState("interviews"); // New state to toggle between interviews and resumes

  const handleProfileClick = () => setProfileDialogOpen(true);
  const handleProfileClose = () => setProfileDialogOpen(false);

  const handleMockInterviewClick = () => setMockInterviewOpen(true);
  const handleMockInterviewClose = () => setMockInterviewOpen(false);

  const handlePasswordReset = async () => {
    try {
      const response = await axios.post(FORGOT_PASSWORD, { email: resetEmail });
      setResetStatus('Password reset link sent! Check your email.');
    } catch (error) {
      setResetStatus('Error sending reset link. Please try again.');
    }
  };

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" rowGap={1}>
            <Button
              startIcon={<HomeIcon sx={{ width: 40, height: 40, color: selected === 0 ? '#ffff' : '#6a58c6' }} />}
              variant="outlined"
              sx={{ width: '48%', borderRadius: 2, border: 0, boxShadow: 2, height: 120, display: 'flex', flexDirection: 'column', gap: 2, color: selected === 0 ? '#ffff' : '#6a58c6', background: selected === 0 && '#6a58c6' }}
              onClick={() => { setSelected(0); setViewMode("interviews"); }}
            >
              Home
            </Button>
            <Button
              startIcon={<ProfileIcon sx={{ width: 40, height: 40, color: selected === 1 ? '#ffff' : '#6a58c6' }} />}
              variant="outlined"
              sx={{ width: '48%', borderRadius: 2, border: 0, boxShadow: 2, height: 120, display: 'flex', flexDirection: 'column', gap: 2, color: selected === 1 ? '#ffff' : '#6a58c6', background: selected === 1 && '#6a58c6' }}
              onClick={() => {
                setSelected(1);
                handleProfileClick();
              }}
            >
              Profile
            </Button>
            <Button
              startIcon={<LearningIcon sx={{ width: 40, height: 40, color: selected === 2 ? '#ffff' : '#6a58c6' }} />}
              variant="outlined"
              sx={{ width: '48%', borderRadius: 2, border: 0, boxShadow: 2, height: 120, display: 'flex', flexDirection: 'column', gap: 2, color: selected === 2 ? '#ffff' : '#6a58c6', background: selected === 2 && '#6a58c6' }}
              onClick={() => { setSelected(2); setViewMode("resumes"); }}
            >
              Resume
            </Button>
            <Button
              startIcon={<CalendarIcon sx={{ width: 40, height: 40, color: selected === 3 ? '#ffff' : '#6a58c6' }} />}
              variant="outlined"
              sx={{ width: '48%', borderRadius: 2, border: 0, boxShadow: 2, height: 120, display: 'flex', flexDirection: 'column', gap: 2, color: selected === 3 ? '#ffff' : '#6a58c6', background: selected === 3 && '#6a58c6' }}
              onClick={() => { setSelected(3); setOpen(true); }}
            >
              Mock Interview
            </Button>
          </Box>
        </Grid>

        <Grid item xs={9}>
          <Grid container spacing={3}>
            {viewMode === "interviews" ? (
              // Interview Schedule Section
              <Grid item xs={4}>
                <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                  <Typography variant="h6">Your Scheduled Interviews</Typography>
                  {studentData?.orgBookings?.length > 0 ? (
                    studentData.orgBookings.map((booking, index) => (
                      <Box key={index} mb={2}>
                        <Typography variant="body1"><strong>Date:</strong> {booking.date}</Typography>
                        <Typography variant="body1"><strong>Time:</strong> {booking.timeSlot}</Typography>
                      </Box>
                    ))
                  ) : (
                    <Typography variant="body1">No interviews scheduled yet.</Typography>
                  )}
                </Paper>
              </Grid>
            ) : (
              // Resume Section
              <Grid item xs={4}>
                <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                  <Typography variant="h6">Your Resumes</Typography>
                  {studentData?.resumes?.length > 0 ? (
                    studentData.resumes.map((resume, index) => (
                      <Box key={index} mb={2}>
                        <Typography variant="body1">{resume.name}</Typography>
                        <Button variant="contained" sx={{ mt: 1, background: '#6a58c6', width: '100%' }}>
                          Download
                        </Button>
                      </Box>
                    ))
                  ) : (
                    <Typography variant="body1">No resumes uploaded yet.</Typography>
                  )}
                  <Button variant="outlined" fullWidth sx={{ mt: 2, color: '#6a58c6', borderColor: '#6a58c6' }}>
                    Upload New Resume
                  </Button>
                </Paper>
              </Grid>
            )}

            <Grid item xs={4}>
              <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                <Typography variant="h6">My Interview Score</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', mt: '50px' }}>
                  <CircularProgressbar
                    value={trainingScore}
                    text={`${trainingScore}`}
                    strokeWidth={2}
                    styles={buildStyles({
                      pathColor: `rgba(106, 88, 198, ${trainingScore / 100})`,
                      textColor: '#6a58c6',
                      trailColor: '#e0e0e0'
                    })}
                  />
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                <Typography variant="h6">Announcements</Typography>
                {announcements.map((item, index) => (
                  <Box key={index} mb={2} p={2} border={1} borderColor="grey.300" borderRadius={2}>
                    <Typography variant="body1">{item.title}</Typography>
                    <Button variant="contained" sx={{ mt: 1, background: '#6a58c6', width: '100%' }}>View</Button>
                  </Box>
                ))}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Profile Dialog */}
      <Dialog open={profileDialogOpen} onClose={handleProfileClose}>
        <DialogTitle>Profile Settings</DialogTitle>
        <DialogContent>
          <TextField
            label="Email for Password Reset"
            fullWidth
            margin="dense"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, mb: 2, background: '#6a58c6' }}
            onClick={handlePasswordReset}
          >
            Send Reset Link
          </Button>
          {resetStatus && <Typography variant="body2" color="textSecondary">{resetStatus}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProfileClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Mock Interview Dialog */}
      <Dialog open={mockInterviewOpen} onClose={handleMockInterviewClose}>
        <DialogTitle>{editingBooking ? 'Edit Interview' : 'Schedule Interview'}</DialogTitle>
        <DialogContent>
          <DateTimeSelection formData={formData} setFormData={setFormData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleScheduleInterview} color="primary">Done</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DashboardComponent;
