const { BASE_URL } = require("./client");


const POST_PARTNER = BASE_URL + '/api/partner/post';









export {POST_PARTNER};