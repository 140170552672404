import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DeleteModal({ open, setOpen, deleteHandler }) {
  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure want to delete?
          </Typography>
          <Grid
            container
            justifyItems="center"
            alignItems="center"
            rowSpacing={5}
            spacing={1}
          >
            <Grid item xs={6} lg={6} md={6}>
              <Button
                style={{ background: "linear-gradient(#55a35e, #55a35e" }}
                variant="contained"
                onClick={() => setOpen(false)}
              >
                cancel
              </Button>
            </Grid>
            <Grid item xs={6} lg={6} md={6}>
              <Button
                onClick={deleteHandler}
                variant="contained"
                style={{ background: "red" }}
              >
                DELETE
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
