import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import LockIcon from '@mui/icons-material/Lock';
import axios from 'axios';
import { USER_REFFERAL } from '../../api/vsc';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useMediaQuery , Tooltip } from '@mui/material';



export default function RefferalTable() {
 const params = useParams();
  const [user, setUser] = React.useState({});
  const isMobile = useMediaQuery('(max-width: 600px)');

  React.useEffect(() => {
    axios
      .get(USER_REFFERAL + params.id)
      .then((response) => {
        setUser(response.data.data);
      })
      .catch((err) => {
      });
  }, []);
  return (
    <TableContainer component={Paper} style={{marginTop:'20px'}} >
      <Table sx={{ minWidth: 100 }} aria-label="simple table">
        <TableHead style={{
      background:'#6a58c6'
    }}>
          <TableRow style={{color:'#fff'}}>
            <TableCell align="left" style={{color:'#fff'}}>Invited Friends</TableCell>
            {!isMobile&&(<TableCell align="left" style={{color:'#fff'}}>Email</TableCell>)}
            <TableCell align="left" style={{color:'#fff'}}>Refferal bonus</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         {user.referredEmails &&
              user.referredEmails.map((referred, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {referred.name}
                  </TableCell>
                  {!isMobile&&<TableCell component="th" scope="row">
                    {referred.email}
                  </TableCell>}
                  <TableCell align="left">
                  <Tooltip title="You receive a referral amount once the referee purchases a program without refund">

                    <Chip
                      style={{ fontWeight: 'bold' }}
                      label={`₹100`}
                      icon={!referred.paid && <LockIcon style={{ color: '#000' }} />}
                    />
                  </Tooltip>

                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}