import React, { useState , useEffect } from 'react';
import { Box, Typography, TextField, Button, Link, Alert, AlertTitle } from '@mui/material';  // Import Alert components
import people from '../LoginOrg/Images/people.png';
import yellow from '../LoginOrg/Images/yellow.png';
import { ORG_REGISTER } from '../../api/orgAuth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SignupOrg = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [orgName, setOrgName] = useState('');

  // State for success and error messages
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    
    if (token || role) {
      if (role === 'user') {
        navigate('/dashboard/' + localStorage.getItem('id'));
      } else if (role === 'org') {
        navigate('/orgdashboard/' + localStorage.getItem('id'));
      } else if (role === 'student') {
        navigate('/studentdashboard/' + localStorage.getItem('id'));
      }
    }
  }, [navigate]);
  // Function to handle signup
  const handleSignup = () => {
    // Reset messages
    setErrorMessage('');
    setSuccessMessage('');

    // Basic validation
    if (!email || !password || !confirmPassword || !orgName) {
      setErrorMessage('Please fill in all fields');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    const signupData = { email, password, orgName };

    axios.post(ORG_REGISTER, signupData)
      .then((response) => {
        setSuccessMessage('Signup successful!');
        navigate('/loginorg');
      })
      .catch((error) => {
        if (error.response) {
          setErrorMessage('Signup failed: ' + error.response.data.message);
        } else {
          setErrorMessage('An error occurred during signup');
        }
      });
  };

  return (
    <>
      <Box
        sx={{
          marginTop: '120px',
          display: 'flex',
          flexDirection: { xs: 'column-reverse', md: 'row' },
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '40%' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 500,
          }}
        >
          <Box
            component={'img'}
            src={people}
            sx={{
              width: { md: 400, xs: '80%' },
              height: 400,
            }}
          />
        </Box>

        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 500,
            flexDirection: 'column',
          }}
        >
          <Typography variant="h4" sx={{ color: '#000', marginBottom: '20px', textAlign: 'center' }}>
            Corporate {'<>'} College Collaboration Portal
          </Typography>
          <Box
            sx={{
              width: { md: '40%', xs: '80%' },
              padding: '20px',
              borderRadius: '10px',
              backgroundColor: '#6a58c6',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <Typography variant="h6" sx={{ color: '#fff', marginBottom: '20px' }}>
              Signup
            </Typography>

            {/* Success and error alerts */}
            {errorMessage && (
              <Alert severity="error" sx={{ width: '100%' }}>
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
              </Alert>
            )}
            {successMessage && (
              <Alert severity="success" sx={{ width: '100%' }}>
                <AlertTitle>Success</AlertTitle>
                {successMessage}
              </Alert>
            )}

            <TextField
              label="Email ID"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                sx: {
                  backgroundColor: 'white',
                  borderRadius: '5px',
                },
              }}
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                sx: {
                  backgroundColor: 'white',
                  borderRadius: '5px',
                },
              }}
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              type="password"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                sx: {
                  backgroundColor: 'white',
                  borderRadius: '5px',
                },
              }}
            />
            <TextField
              label="Organization Name"
              variant="outlined"
              fullWidth
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              InputProps={{
                sx: {
                  backgroundColor: 'white',
                  borderRadius: '5px',
                },
              }}
            />
            <Button variant="contained" sx={{ background: '#000' }} onClick={handleSignup}>
              Signup
            </Button>

            {/* Already have an account? Sign in link */}
            <Typography sx={{ color: '#fff', marginTop: '10px' }}>
              Already have an account?{' '}
              <Link href="/loginorg" sx={{ color: '#FFD700' }}>Sign in</Link>
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Image at the bottom of the page */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100px',
          backgroundImage: `url(${yellow})`,
          backgroundRepeat: 'repeat-x',
          backgroundSize: '20%',
          display: { xs: 'none', md: 'flex' },
        }}
      />
    </>
  );
};

export default SignupOrg;
