import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import NewVirtualCampusStartup from './components/NewVirtualCampusStartup/NewVirtualCampusStartup';
import Markoknow_kickstart from './components/NewVirtualCampusStartup/Program/Markoknow_kickstart';
import Markoknow_workX from './components/NewVirtualCampusStartup/Program/Markoknow_workX';
import EmailVerification from './components/email/EmailVerification';
import EmailVerSignup from './components/email/EmailVerSignup';
import EmailTestimonial from './components/email/EmailTestimonial';
import Kickstart_details from './components/NewVirtualCampusStartup/Program/Course_details/Kickstart_details';
import Course_Landing from './components/NewVirtualCampusStartup/Program/Course_Landing';
import CarrerPage from './components/Career/CareerPage';
import Signup from './components/auth/Signup';
import Login from './components/auth/Login';
import MyDeshboard from './components/auth/myDeshboard';
import axios from 'axios';
import Navbar from './components/Navbar/Navbar';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/Terms&Conditions';
import Projects from './components/Projects';
import ProjectLogin from './components/Projects/ProjectLogin';
import Participate from './components/Projects/Participate';
import DataCourse from './components/NewVirtualCampusStartup/Program/VideoPlayer/Courses/DataCourse';
import WatchNow from './components/NewVirtualCampusStartup/watchnow/index';
import Video from './components/NewVirtualCampusStartup/watchnow/Video';
import VirtualCampus from './components/NewVirtualCampusStartup/VirtualCampus/Virtualcampus';
import Funding from './components/NewVirtualCampusStartup/Funding/index';
import Play_Video from './components/NewVirtualCampusStartup/Program/VideoPlayer/Play_Video';
import PaymentCheckout from './components/NewVirtualCampusStartup/Program/PaymentCheckout/PaymentCheckout';
import PaymentSuccess from './components/NewVirtualCampusStartup/Program/PaymentCheckout/PaymentSuccess';
import Blogs from './components/Blog/NewBlog';
import BlogPage from './components/Blog/blogPage';
import Feedback from './components/Feedback';
import ForgetPass from './components/forgotPassword/ForgotPass.js';
import ResetPassword from './components/forgotPassword/ResetPassword.jsx';
import Certificate from './components/NewVirtualCampusStartup/Program/VideoPlayer/certificate.jsx';
import ViewCertificate from './components/NewVirtualCampusStartup/Program/VideoPlayer/viewcertificate.jsx';
import Dashboard from './components/Dashboard/index.jsx';
import RefundPolicy from './components/RefundPolicy/index.jsx';
import About from './components/About/index';
import Contact from './components/Contact/index';
import CourseForm from './components/CourseForm/index';
import Reader from './components/Projects/Reader';
import CaseStudy from './components/Projects/CaseStudyIndex';
import CaseStudies from './components/CaseStudy.jsx/index';
import PopupChat from './components/PopupChat/index';
import Course from './components/Course/index';
import UnicornCasestudies from './components/CaseStudy.jsx/UnicornCasestudies';
import { USER } from './api/vsc';
import BadgeNotification from './components/Badge/BadgeNotification';
import Badges from './components/Badge/Badges';
import NewCertificate from './components/Certificate/index';
import CertificatePromptInternship from './components/CertificatePromptInternship/index';
import CertificatePromptProgram from './components/CertificatePromptProgram/index';
import CertificatePromptCapstone from './components/CertificatePromptCapstone/index';
import NotificationBar from './components/Navbar/NotificationBar';
import ApplyNowButton from './components/ApplyNowButton';
import Leaderboard from "./components/Leaderboard/Leaderboard.js"
import MobileView from "./components/Leaderboard/MobileView"
import Reedom from "./components/Redeem/index.js"
import PromptAI from './components/CourseLandingPage/PromptAI'
import URLlist from './components/courcesAboutURL/AboutCourses.js'
import CreateCourse from './components/courcesAboutURL/CreateCourse.js'
import CourseWithPayment from './components/Course/CourseWithPayment';
import Events from './components/Event';
import CertificateGen from './components/Event/CertificateGen';
import EventFormPage from './components/Event/EventFormPage';
import EarnedCertificates from './components/EarnedCertificates';
import Product from './components/CourseLandingPage/Product/index';
import CertificateProductExperience from './components/CertificateProductExperience';
import CertificateProductProject from './components/CertificateProductWorkEx';
import CertificateProductWorkEx from './components/CertificateProductProject';
import CertificateEvents from './components/Event/CertificateGen/Certificate';
import CertificateVerification from './components/CertificateVerification';
import CampusAmbassador from './components/CampusAmbassador';
import Refferal from './components/Dashboard/SideDrawer/Refferal';
import DirectCheckoutLink from './components/DirectCheckoutLink';
import { HelmetProvider } from 'react-helmet-async';
import Confetti from './components/Confetti';
import ManagementConsulting from './components/CourseLandingPage/ManagemnetConsulting';
import PythonCoder from './components/CourseLandingPage/PythonCoder';
import FullStackDeveloper from './components/CourseLandingPage/FullStackDeveloper';
import FinancialAnalyst from './components/CourseLandingPage/FinancialAnalyst';
import AIMLCODER from './components/CourseLandingPage/AIMLCODER';
import PowerBiAnalyst from './components/CourseLandingPage/PowerBiAnalyst';
import DataAnalyst from './components/CourseLandingPage/DataAnalyst';
import BusinessAnalyst from './components/CourseLandingPage/BusinessAnalyst';
import MarketingStack from './components/CourseLandingPage/MarketingStack';

import CertificateManagementConsulting from './components/CertficateManagementConsulting';
import CertificateManagementConsultingWorkex from './components/CertficateManagementConsultingWorkEx';
import CertificateManagementConsultingProject from './components/CertficateManagementConsultingProject';
import CertificatePythonCoderProgram from './components/CertificatePythonCoder';
import CertificatePythonCoderProject from './components/CertificatePythonCoderProject';
import CorporateExposureProgram from './components/CorporateExposureProgram';
import Mentor from './components/Mentor';
import Hire from './components/Hire';
import Trainee from './components/Trainee';
import Scholarship from './components/Scholarship';
import MockInterview from './components/MockInterview';
import LoginOrg from './components/LoginOrg/index.jsx';
import SignupOrg from './components/SignupOrg/index.jsx';
import OrgDashboard from './components/OrgDashboard/index.jsx';
import StudentDashboard from './components/StudentDashboard/index.jsx';
import PartnerWithUs from './components/PartnerWithUs/index.jsx';
import Faq from './components/Faq'


const App = () => {
  const [isAuth, setIsAuth] = useState(window.localStorage.getItem('isAuth') || false);
  const helmetContext = {};
  useEffect(() => {
    ReactGA.initialize('UA-211886888-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // const { USER } = require('./api/vsc');
  const [data, setData] = useState({});
  const [showNavbar, setShowNavbar] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if(isAuth){
      axios
      .get(USER, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        setData(response.data);
        
      })
      .catch((error) => {
        console.error(error);
      });
    }
    
  }, [isAuth]);
  useEffect(() => {
    setShowNavbar(!location.pathname.toLowerCase().includes('/dashboard') && !location.pathname.includes('/program/videoplayer'));
  }, [location]);
  return (
    <HelmetProvider context={helmetContext}>
    <div className="App">
      {data && data.completedBadges && (!data.completedBadges.includes('SignupBadge') ||  (( (data.completedWatchnow && data.completedWatchnow.length === 1) ||
          (data.completedBlog && data.completedBlog.length === 1) ||
          (data.completedCaseStudy && data.completedCaseStudy.length === 1))
        && !data.completedBadges.includes('FirstCompleted'))) && <Confetti />}
      {showNavbar && <Navbar isAuth={isAuth} setIsAuth={setIsAuth} />}
      {data && data.completedBadges && !data.completedBadges.includes('SignupBadge') && <BadgeNotification o={'Signup Badge'} />}
      {data &&
        ((data.completedWatchnow && data.completedWatchnow.length === 1) ||
          (data.completedBlog && data.completedBlog.length === 1) ||
          (data.completedCaseStudy && data.completedCaseStudy.length === 1))
        && !data.completedBadges.includes('FirstCompleted') && <BadgeNotification o={'First Completed'} />}

      {data &&
        data.completedBadges &&
        data.completedBadges.includes('SignupBadge') && data.completedBadges.includes('ApplyNow') &&
        data.completedBadges.includes('FirstCompleted') && !data.completedBadges.includes('Profile')
        && <BadgeNotification o={'Profile'} />}
        {data && data.streakCount &&
        data.streakCount === 7 &&
        !data.completedBadges.includes('First7Goals') 
        && <BadgeNotification o={'First 7 Goals'} />}
        {/* {data && data.streakCount &&
          data.streakCount >= 10 &&
        !data.completedBadges.includes('10Goals') 
        && <BadgeNotification o={'10 Goals'} />}
        {data && data.streakCount &&
          data.streakCount >= 20 &&
        !data.completedBadges.includes('20Goals') 
        && <BadgeNotification o={'20 Goals'} />}
        {data && data.streakCount &&
          data.streakCount >= 30 &&
        !data.completedBadges.includes('30Goals') 
        && <BadgeNotification o={'30 Goals'} />}
        {data && data.streakCount &&
          data.streakCount >= 90 &&
        !data.completedBadges.includes('3MonthConsistent') 
        && <BadgeNotification o={'3 Month Consistent'} />}
        {data && data.streakCount &&
          data.streakCount >= 180 &&
        !data.completedBadges.includes('6MonthConsistent') 
        && <BadgeNotification o={'6 Month Consistent'} />}
        {data && data.dailyStreak &&
        data.dailyStreak.length === 3 &&
        !data.completedBadges.includes('TopLeaderboard') 
        && <BadgeNotification o={'Top Leaderboard'} />}
        {data && data.courses &&
        data.courses.length > 0 &&
        !data.completedBadges.includes('FirstCoursePurchase') 
        && <BadgeNotification o={'First Course Purchase'} />} */}
      <Routes>
        <Route path="/" element={<NewVirtualCampusStartup isAuth={isAuth} setIsAuth={setIsAuth} />} />
        <Route
          exact
          path="/watch_now"
          element={<WatchNow isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route exact path="/forgotpassword" element={<ForgetPass />} />
        <Route exact path="/resetpassword/:token" element={<ResetPassword />} />
        <Route exact path="/watch_now/watch" element={<Video />} />
        <Route exact path="/virtual_campus" element={<VirtualCampus isAuth={isAuth} />} />
        {/* <Route exact path="/fund" element={<Funding isAuth={isAuth} />} /> */}
        <Route
          path="/program/markoknow-kickstart"
          element={<Kickstart_details isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route
          path="/program/markoknow_workX/landing-page"
          element={<Markoknow_workX isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route
          path="/program/markoknow-kickstart/landing-page"
          element={<Markoknow_kickstart isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route
          path="/program/markoknow-kickstart/courseform/:courseid"
          element={<CourseForm isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route exact path="/signup" element={<Signup isAuth={isAuth} setIsAuth={setIsAuth} />} />
        <Route exact path="/verify_email/:token" isAuth={isAuth} element={<EmailVerification />} />
        <Route
          exact
          path="/verify_signup_email/:token"
          isAuth={isAuth}
          element={<EmailVerSignup />}
        />
        <Route
          exact
          path="/verify_testimonial/:token"
          isAuth={isAuth}
          element={<EmailTestimonial />}
        />
        <Route exact path="/login" element={<Login isAuth={isAuth} setIsAuth={setIsAuth} />} />
        <Route
          exact
          path="/program/videoplayer/:id"
          element={<Play_Video isAuth={isAuth} setIsAuth={setIsAuth} data={data}/>}
        />
        <Route
          exact
          path="/program/courses"
          element={<DataCourse isAuth={isAuth} setIsAuth={setIsAuth} />}
        />

        {/* <Route
          exact
          path="/program/paymentcheckout/:id/:userId"
          element={<PaymentCheckout isAuth={isAuth} setIsAuth={setIsAuth} />}
        /> */}
        <Route
          exact
          path="/program/paymentsuccess"
          element={<PaymentSuccess />}
        />
          <Route
            exact
            path="/program/paymentcheckout/:id/:category"
            element={<PaymentCheckout isAuth={isAuth} setIsAuth={setIsAuth} />}
          />
          <Route 
            exact
            path="/program/paymentsuccess"
            element={<PaymentSuccess/>}
          />
          
          <Route
            path="/myDashboard/:id"
            element={
              isAuth ? (
                <MyDeshboard isAuth={isAuth} setIsAuth={setIsAuth} />
              ) : (
                <Login setIsAuth={setIsAuth} />
              )
            }
          />
          <Route
            exact
            path="/Course_landing"
            element={<Course_Landing isAuth={isAuth} setIsAuth={setIsAuth} />}
          />
          <Route exact path="/Career" element={<CarrerPage />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
          <Route exact path="/terms_conditions" element={<TermsConditions />} />
          <Route exact path="/projects" element={<Projects isAuth={isAuth} setIsAuth={setIsAuth} />} />
          <Route exact path="/projectLogin" element={<ProjectLogin isAuth={isAuth} setIsAuth={setIsAuth}/>} />
          <Route exact path="/participate" element={<Participate isAuth={isAuth} setIsAuth={setIsAuth}/>} />
          <Route exact path="/blog" element={<Blogs />}/>
          <Route exact path="/blog/:id" element={<BlogPage/>}/>
          <Route exact path="/feedback" element={<Feedback/>} />
          <Route exact path="/certificate/:id" element={<NewCertificate/>} />
          <Route exact path="/viewcertificate/:id" element={<ViewCertificate />} />
          <Route exact path="/dashboard/:id" element={<Dashboard isAuth={isAuth} setIsAuth={setIsAuth}/>}/>
          <Route exact path="/refundpolicy" element={<RefundPolicy />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact/> } />
          <Route exact path="/casestudy/:id" element={<Reader />} />
          <Route exact path="/casestudy" element={<CaseStudy/>}/>
          <Route exact path="/casestudies/:id" element={<CaseStudies/>} />
          <Route exact path="/course" element={<Course/>} />
          <Route exact path="/exclusive/casestudy/viewer/:id" element={<UnicornCasestudies isAuth={isAuth} setIsAuth={setIsAuth}/>} />
          <Route exact path="/badges" element={<Badges isAuth={isAuth} />} />
          <Route exact path="/newcertifcate" element={<NewCertificate/>} />
          <Route exact path="/promptai" element={<PromptAI />} />
          <Route exact path="/productmanagement" element={<Product />} />


        <Route
          path="/myDashboard/:id"
          element={
            isAuth ? (
              <MyDeshboard isAuth={isAuth} setIsAuth={setIsAuth} />
            ) : (
              <Login setIsAuth={setIsAuth} />
            )
          }
        />
        <Route
          exact
          path="/Course_landing"
          element={<Course_Landing isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route exact path="/Career" element={<CarrerPage />} />
        <Route exact path="/privacy" element={<PrivacyPolicy />} />
        <Route exact path="/terms_conditions" element={<TermsConditions />} />
        <Route exact path="/projects" element={<Projects isAuth={isAuth} setIsAuth={setIsAuth} />} />
        <Route exact path="/projectLogin" element={<ProjectLogin isAuth={isAuth} setIsAuth={setIsAuth} />} />
        <Route exact path="/participate" element={<Participate isAuth={isAuth} setIsAuth={setIsAuth} />} />
        <Route exact path="/blog" element={<Blogs />} />
        <Route exact path="/blog/:id" element={<BlogPage />} />
        <Route exact path="/feedback" element={<Feedback />} />
        <Route exact path="/certificate/:id" element={<NewCertificate />} />
        <Route exact path="/viewcertificate/:id" element={<ViewCertificate />} />
        <Route exact path="/dashboard/:id" element={<Dashboard isAuth={isAuth} setIsAuth={setIsAuth} />} />
        <Route exact path="/refundpolicy" element={<RefundPolicy />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/casestudy/:id" element={<Reader />} />
        <Route exact path="/casestudy" element={<CaseStudy />} />
        <Route exact path="/casestudies/:id" element={<CaseStudies />} />
        <Route exact path="/course" element={<Course />} />
        <Route exact path="/exclusive/casestudy/viewer/:id" element={<UnicornCasestudies isAuth={isAuth} setIsAuth={setIsAuth} />} />
        <Route exact path="/badges" element={<Badges isAuth={isAuth} />} />
        <Route exact path="/newcertifcate" element={<NewCertificate />} />
        <Route exact path="/leaderboard" element={<Leaderboard />} />
        <Route exact path="/certificate/prompt/internship/:id" element={<CertificatePromptInternship />} />
        <Route exact path="/certificate/prompt/program/:id" element={<CertificatePromptProgram />} />
        <Route exact path="/certificate/prompt/capstone/:id" element={<CertificatePromptCapstone />} />
        <Route exact path="/certificate/product/experience/:id" element={<CertificateProductExperience />} />
        <Route exact path="/certificate/product/project/:id" element={<CertificateProductProject />} />
        <Route exact path="/certificate/product/workex/:id" element={<CertificateProductWorkEx />} />
        <Route exact path="/certificate/managementconsulting/:id" element={<CertificateManagementConsulting />} />
        <Route exact path="/certificate/managementconsulting/workex/:id" element={<CertificateManagementConsultingWorkex />} />
        <Route exact path="/certificate/managementconsulting/project/:id" element={<CertificateManagementConsultingProject />} />
        <Route exact path="/certificate/pythoncoder/:id" element={<CertificatePythonCoderProgram />} />
        <Route exact path="/certificate/pythoncoder/project/:id" element={<CertificatePythonCoderProject />} />
        <Route exact path="/newcourse" element={<URLlist />} />
        <Route exact path="/courseprogram/:id" element={<CreateCourse />} />
        <Route exact path="/redeem" element={<Reedom />} />
        <Route exact path="/courses" element={<CourseWithPayment isAuth={isAuth}/>} />
        <Route exact path="/events" element={<Events isAuth={isAuth}/>} />
        <Route exact path="/eventsform/:eventId" element={<EventFormPage isAuth={isAuth}/>} />
        <Route exact path="/earnedcertificates/:id" element={<EarnedCertificates isAuth={isAuth}/>} />
        <Route exact path="/events/certificate/generate" element={<CertificateGen isAuth={isAuth}/>}/>
        <Route exact path="/events/certificate/:id/:userId" element={<CertificateEvents isAuth={isAuth}/>}/>
        <Route exact path="/verifycertificate" element={<CertificateVerification isAuth={isAuth}/>}/>
        <Route exact path="/campusambassador" element={<CampusAmbassador isAuth={isAuth}/>}/>
        <Route exact path="/refferal" element={<Refferal isAuth={isAuth}/>}/>
        <Route exact path="/checkoutpayment/:arr" element={<DirectCheckoutLink isAuth={isAuth}/>}/>
        <Route exact path="/checkoutpayment/training/:arr" element={<DirectCheckoutLink isAuth={isAuth}/>}/>
        <Route exact path="/managementconsulting" element={<ManagementConsulting isAuth={isAuth}/>}/>
        <Route exact path="/pythoncoder" element={<PythonCoder isAuth={isAuth}/>}/>
        <Route exact path="/fullstackdeveloper" element={<FullStackDeveloper isAuth={isAuth}/>}/>
        <Route exact path="/financialanalyst" element={<FinancialAnalyst isAuth={isAuth}/>}/>
        <Route exact path="/aimlcoder" element={<AIMLCODER isAuth={isAuth}/>}/>
        <Route exact path="/powerbianalyst" element={<PowerBiAnalyst isAuth={isAuth}/>}/>
        <Route exact path="/dataanalyst" element={<DataAnalyst isAuth={isAuth}/>}/>
        <Route exact path="/businessanalyst" element={<BusinessAnalyst isAuth={isAuth}/>}/>
        <Route exact path="/marketingstack" element={<MarketingStack isAuth={isAuth}/>}/>
        <Route exact path="/corporateexposureprogram" element={<CorporateExposureProgram isAuth={isAuth}/>}/>      
        <Route exact path="/mentor" element={<Mentor isAuth={isAuth}/>}/>     
        <Route exact path="/hire" element={<Hire isAuth={isAuth}/>}/>     
        <Route exact path="/trainee" element={<Trainee/>}/>     
        <Route exact path="/scholarship" element={<Scholarship/>}/> 
        {/* <Route exact path="/mockInterview" element={<MockInterview isAuth={isAuth} setIsAuth={setIsAuth}/>}/>  */}
        <Route exact path="/loginorg" element={<LoginOrg isAuth={isAuth} setIsAuth={setIsAuth} data={data}/>}/> 
        <Route exact path="/signuporg" element={<SignupOrg isAuth={isAuth} setIsAuth={setIsAuth} data={data}/>}/> 
        <Route exact path="/orgdashboard/:id" element={<OrgDashboard isAuth={isAuth} setIsAuth={setIsAuth} data={data}/>}/> 
        <Route exact path="/studentdashboard/:id" element={<StudentDashboard isAuth={isAuth} setIsAuth={setIsAuth} data={data}/>}/> 
        <Route exact path="/partnerwithus" element={<PartnerWithUs isAuth={isAuth} setIsAuth={setIsAuth} data={data}/>}/> 
        <Route exact path="/faq" element={<Faq isAuth={isAuth} setIsAuth={setIsAuth} data={data}/>}/> 



      </Routes>
      {location.pathname ===  "/" && <ApplyNowButton />}
      <PopupChat />
    </div>
    </HelmetProvider>
  );
};

export default App;
