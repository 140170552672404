import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Box, 
  CircularProgress, 
  Button, 
  Grid, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Avatar,
  LinearProgress,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  SvgIcon
} from '@mui/material';
import axios from 'axios';
import { ORG_STUDENT_BOOK, ORG_USER } from '../../api/orgAuth';
import DateTimeSelection from './DateTimeSelection';
import DashboardComponent from './DashboardComponent';  // Import your DashboardComponent

// Custom Step Icon Component
const CustomStepIcon = ({ completed, active }) => {
  return (
    <SvgIcon>
      {completed ? (
        <path d="M10 15.172l-4.586-4.586 1.414-1.414L10 12.344l6.586-6.586 1.414 1.414z" fill="white" />
      ) : active ? (
        <circle cx="12" cy="12" r="8" fill="white" /> // Current step is white
      ) : (
        <circle cx="12" cy="12" r="8" fill="rgba(255, 255, 255, 0.5)" /> // Inactive step is slightly transparent
      )}
    </SvgIcon>
  );
};

// Custom Step Connector
const CustomStepConnector = () => (
  <StepConnector sx={{
    height: 3,
    backgroundColor: 'rgba(255, 255, 255, 0.3)', // Line color
  }} />
);

const StudentDashboard = () => {
  const [studentData, setStudentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ date: '', timeSlot: '' });
  const [refresh, setRefresh] = useState(false);
  const [editingBooking, setEditingBooking] = useState(null);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(ORG_USER + window.localStorage.id);
        setStudentData(response.data);
        setRefresh(false);
      } catch (err) {
        setError('Failed to fetch student data');
      } finally {
        setLoading(false);
      }
    };
    fetchStudentData();
  }, [refresh]);

  const levels = [
    { 
      name: 'Level 1', 
      completed: studentData?.orgBookings.length > 0, 
      caption: 'Booking your Slot' 
    },
    { 
      name: 'Level 2', 
      completed: studentData?.completedLevels > 1, 
      caption: 'Appearing for Interview' 
    },
    { 
      name: 'Level 3', 
      completed: studentData?.completedLevels > 2, 
      caption: 'View SWOT Report' 
    }
  ];

  const handleScheduleInterview = async () => {
    try {
      const response = await axios.put(
        ORG_STUDENT_BOOK + window.localStorage.id,
        formData
      );
      setRefresh(true);
      setOpen(false);
    } catch (err) {
      console.error('Error scheduling interview:', err);
    }
  };

  const handleEditBooking = (booking) => {
    setEditingBooking(booking);
    setFormData({ date: booking.bookingDate, timeSlot: booking.time });
    setOpen(true);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h6" color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ marginTop: '100px', color: '#fff', width: '100%' }}>
      <Box
        sx={{
          backgroundColor: '#6a58c6',
          borderRadius: '8px',
          textAlign: 'center',
          color: '#fff',
          padding: 5
        }}
      >
        <Grid container spacing={3}>
          {/* Profile Section */}
          <Grid item xs={12} md={3}>
            <Avatar 
              alt="Student Image" 
              src={studentData?.user?.profilePicture || '/default-avatar.jpg'} 
              sx={{ width: 120, height: 120, margin: 'auto' }} 
            />
            <Typography variant="h5" mt={2}>{studentData?.user?.name}</Typography>
            <Typography variant="body1">{studentData?.user?.role}</Typography>
            <Box mt={2} display="flex" justifyContent="center" alignItems="center">
            </Box>
          </Grid>
  
          {/* Progress Section */}
          <Grid item xs={12} md={9}>
            <Typography variant="h6" textAlign={'left'} sx={{ marginBottom: '20px' }}>
              Hi {studentData?.user?.name},
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginTop: '40px'
              }}
            >
            <Stepper 
              activeStep={levels.findIndex(level => level.completed) + 1} 
              alternativeLabel 
              // connector={<CustomStepConnector />}
              sx={{
                width: '60%'
              }}
            >
              {levels.map((level, index) => (
                <Step key={index}>
                  <StepLabel 
                    sx={{ color: '#fff' }} 
                    StepIconComponent={(props) => <CustomStepIcon {...props} completed={level.completed} active={props.active} />}
                  >
                    {level.name} <br/>
                    {level.caption}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            </Box>
            
          </Grid>
        </Grid>
      </Box>

      {/* Dashboard Component */}
      <Box sx={{ marginTop: '20px' }}>
        <DashboardComponent 
          interviewStatus="Confirmed" 
          daysLeft={3} 
          trainingScore={75} 
          announcements={[
            { title: 'New Mock Interview Slots Available' },
            { title: 'Upcoming Event: Career Fair' },
          ]} 
          editingBooking={editingBooking}
          studentData={studentData}
          formData={formData}
          setFormData={setFormData}
          handleScheduleInterview={handleScheduleInterview}
          open={open}
          setOpen={setOpen}
        />
      </Box>
  
      {/* Dialog for scheduling or editing interview */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{editingBooking ? 'Edit Booking' : 'Schedule Interview'}</DialogTitle>
        <DialogContent>
          <DateTimeSelection formData={formData} setFormData={setFormData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleScheduleInterview} color="primary">Done</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StudentDashboard;
